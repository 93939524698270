html {
  height: -webkit-fill-available;
  user-select: none;
}


body {
  margin: 0;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  height: -webkit-fill-available;
}

*::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}
.scrollbar-hidden {
  scrollbar-width: 1px; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: block; /* Chrome, Safari, Opera */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
}

.parentDiv {
  background-image: url("/src/assets/images/newb.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  overflow-y: hidden;
}

@media (max-width: 560px) {
  .parentDiv {
    padding: 0;
    min-height: 100vh;
    background-blend-mode: multiply;
    background-color: #000000c4;
    overflow: scroll;
  }
}

.overWord {
  overflow-wrap: anywhere;
  font-size: 24px;
}
@media (max-width: 560px) {
  .overWord {
    font-size: 14px;
  }
}

.backgroundImg {
  position: absolute;
  width: 1920px;
  height: 1080px;
  left: 0px;
  top: 0px;

  mix-blend-mode: luminosity;
}

.mainContent_InspectionFlags {
  min-height: 90vh;
  flex-direction: column;
}
.mainContent {
  height: 76vh;
}
@media (max-width: 560px) {
  .mainContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    /*padding-top: 20%;*/
    height: unset;
  }
}
/*@media (max-width: 420px) {
  .mainContent {
    padding-top: 20%;
  }
}*/

.check {
  margin-left: 5px;
}

@media (max-width: 560px) {
  .logoContainer {
    height: 58px;
  }
}

.general-bar {
  display: none;
}

@media (max-width: 560px) {
  .general-bar {
    display: flex;
    width: 85%;
  }
}

.bottomContent {
  height: 9vh;
  padding: 10px;
}

@media (max-width: 560px) {
  .bottomContent {
    display: none;
  }
}

@media (max-width: 380px) {
  .logoContainer {
    position: unset;
    background: black;
    top: 1px;
    height: 40px;
  }

  .logoContainer.bottom {
    display: none;
  }
}

.eventIcon {
  width: 40px;
  margin-top: 5px;
  margin-left: 15px;
}

@media (max-width: 560px) {
  .eventIcon {
    width: 30px;
    margin-left: 5px;
  }
}

.eventTotal {
  width: 48px;
  margin-top: 5px;
  margin-left: 15px;
}

@media (max-width: 560px) {
  .eventTotal {
    width: 38px;
    margin-top: 5px;
    margin-left: 15px;
  }
}

@media (max-width: 380px) {
  .eventTotal {
    width: 30px;
    margin-top: 5px;
    margin-left: 5px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.burger-click::before {
  animation: down-rotate 0.6s ease-out both;
}

.burger-click::after {
  animation: up-rotate 0.6s ease-out both;
  top: 30px;
}

.burger-click span {
  animation: hide 0.2s ease-out forwards;
}

.burger-unclick span {
  animation: show 0.6s forwards;
}

.burger-unclick::before {
  animation: down-up-rotate 0.2s ease-out both;
}

.burger-unclick::after {
  animation: up-down-rotate 0.2s ease-out both;
  top: 37px !important;
}

.nav-show {
  transform: translateY(50%) !important;
  opacity: 1 !important;
}

@media (max-width: 560px) {
  .nav-show {
    transform: translateY(54%) !important;
    opacity: 1 !important;
  }
}

.circle {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
}
.c-r {
  background-color: red;
}
.c-g {
  background-color: green;
}
.c-y {
  background-color: yellow;
}

.c-b {
  background-color: rgb(0, 128, 255);
}

#top,
#bottom {
  color: #000;
  font: bold 18px/1 Arial;
  margin: 10px 0;
  width: 100%;
  visibility: hidden;
}

@keyframes up-rotate {
  0% {
    animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
    transform: translateY(0px);
  }

  30% {
    transform-origin: center;
    animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: translateY(-10px);
  }

  100% {
    transform-origin: center;
    transform: translateY(-10px) rotate(45deg) scale(0.9);
  }
}

@keyframes up-down-rotate {
  0% {
    transform-origin: center;
    transform: translateY(-10px) rotate(45deg) scale(0.9);
  }

  100% {
    animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
    transform: translateY(0px);
  }
}

@keyframes down-rotate {
  0% {
    animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
    transform: translateY(0px);
  }

  30% {
    transform-origin: center;
    animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: translateY(10px);
  }

  100% {
    transform-origin: center;
    transform: translateY(10px) rotate(-45deg) scale(0.9);
  }
}

@keyframes down-up-rotate {
  0% {
    transform-origin: center;
    transform: translateY(10px) rotate(-45deg) scale(0.9);
  }

  100% {
    animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
    transform: translateY(0px);
  }
}

@keyframes hide {
  1% {
    opacity: 1;
    transform: translateX(0px);
  }

  30% {
    opacity: 0.5;
    transform: translateX(15px);
  }

  100% {
    opacity: 0;
    transform: translateX(30px);
  }
}

@keyframes show {
  1% {
    opacity: 0;
    transform: translateX(30px);
  }

  30% {
    opacity: 0.5;
    transform: translateX(15px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.cancelAc {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.cancelAc .action {
  color: #f12020;
  margin-left: 5px;
}

.completeAc {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.completeAc .action {
  color: #11ae3b;
  margin-right: 5px;
}

.circle-stat {
  border-radius: 50%;
  border: 1px solid gray;
}
.Y {
  background-color: #f0ecc2 !important;
}

.Y-U {
  background-color: #dfdc87 !important;
}

.G {
  background-color: #d4f0c2 !important;
}
.G-U {
  background-color: #d4f0c2 !important;
}
.R {
  background-color: #f0c2db !important;
}
.R-U {
  background-color: #f072db !important;
}
.B {
  background-color: #bed9eb !important;
}
.B-U {
  background-color: #bed9eb !important;
}
.machine-active {
  border: 4px solid #227cd1 !important;
}

.icon-select {
  position: absolute;
  left: -20px;
  top: 32px;
  display: none;
}

.machine-active > .icon-select {
  display: block;
}

.Back {
  position: absolute;
  top: 19px;
  left: 29px;
}

.borderGradient {
  background: linear-gradient(0deg, #bababa 100% 74.53%, #ffffff 0%);
  padding: 2px;
  border-radius: 10px;
}

@media (min-width: 560px) {
  .borderGradient {
    width: 500px;
  }
}
.hidden {
  display: none;
}

/* swiper - slider */
.swiper-slide {
  width: auto !important;
}

/* Resolved Flags */

.resTitle {
  margin: 14px 0 28px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
}

.resItem {
  border-radius: 10px;
  background: linear-gradient(87.57deg, #6d9fce 54.25%, #ffffff 104.4%);
  color: #161616;
  padding: 8px 9px 3px;
  height: 80px;
}
.resExclItem {
  border-radius: 10px;
  background: linear-gradient(87.57deg, #a3a3a3 54.25%, #ffffff 104.4%);
  color: #161616;
  padding: 5px;
  height: 60px;
  display: flex;
}

.resExclItem-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 6px;
}
.resItem-blue {
  background: linear-gradient(87.57deg, #6d9fce 54.25%, #ffffff 104.4%);
}

.resItem-gray {
  background: linear-gradient(87.57deg, #a3a3a3 54.25%, #ffffff 104.4%);
}

.resItem-border {
  padding: 2px;
  background: linear-gradient(0deg, #000 0%, #bababa 100%);
  border-radius: 10px;
  margin-bottom: 19px;
}

.resItem-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 6px;
  border-bottom: 0.974897px solid #161616;
}

.resItem-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2px;
  min-height: 50%;
}

.resItem-machineCode {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  padding: 2px 7px 4px;
  background: #ffffff;
  border: 0.6px solid #161616;
  border-radius: 3px;
  width: 40px;
  height: 22px;
  text-align: center;
}

.resExclItem-machineCode {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  padding: 2px;
  background: #ffffff;
  border: 0.6px solid #161616;
  border-radius: 3px;
  width: 50px;
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resItem-machineName {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  margin-left: 13px;
  line-break: anywhere;
}

.resItem-machineName-small {
  font-size: 12px;
}

.resItem-machineName-small {
  font-size: 12px;
}

.resItem-workerName {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  max-width: 105px;
}

.resItem-date {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
}

.search__input {
  background: none;
  border: 0;
  width: 100%;
  color: white;
  font-size: 18px;
}

.search__input:focus {
  outline: none;
}
.search {
  width: 100%;
}

fieldset {
  border: none;
  margin: 2.5rem 0;
  padding: 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

label {
  display: block;
  font-size: 1.2rem;
  color: #4b5563;
}

/* [multiple],
[type="date"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea */
.log-text {
  display: block;
  width: 450px;
  margin-top: 1rem;
  background-color: #fff;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
  border: none;
  border-bottom: 1px solid orange;
  outline: none;
}
.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  background-color: orange;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

/* button[type="submit"] {
  background: #3b82f6;
  color: #fff;
  cursor: pointer;
  font-size: 1.3rem;
  border: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;

  &:hover {
    background: darken(#3b82f6, 15%);
  }
} */

.form-required-message {
  visibility: hidden;
}

input[data-touched="true"],
textarea[data-touched="true"] {
  &:invalid {
    border: 1px solid #f44336;
    & ~ .form-required-message {
      color: #f44336;
      padding-top: 0.5rem;
      visibility: visible;
    }
  }
}

#upload {
  visibility: hidden;
  display: none;
}

.skipButton {
  margin-left: 20px;
  background: none;
  border: none;
  color: #3b82f6;
  cursor: pointer;
  font-size: 22px;
}

.doors {
  position: absolute;
  top: 0;
}
.miniDoor {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #333;
  padding: 10px;
  font-size: 25px;
  text-align: center;
  color: #fff;
  line-height: 1.5em;
  border: none;
  transition: 0.1s;
}

.miniDoor:active {
  background-color: #000000;
  transform-origin: 0% 50%;
}

.miniDoor > img {
  transition: 0.1s;
  width: 20px;
}
.miniDoor.active > img {
  width: 25px;
}
.miniDoor.active {
  background-color: #000000;
  transform-origin: 0% 50%;
}
.miniDoor.right:hover {
  opacity: 1;
  transform-origin: 0% 50%;
  background-color: #2a2b2c;
  cursor: pointer;
  pointer-events: all;
}

.miniDoor.left:hover {
  backface-visibility: hidden;

  opacity: 1;
  transform-origin: 100% 50%;
  background-color: #2a2b2c;
  cursor: pointer;
  pointer-events: all;
}

.swiper-button-next::after {
  content: "" !important;
}
.swiper-button-prev::after {
  content: "" !important;
}
.custom-menu-item {
  color: gray;

  @media screen and (min-width: 2000) {
   
    font-size: 30px;
  }
}

.navbar {
  border-bottom: 1px solid rgb(255, 255, 255);
  padding-bottom: 0.01vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(0, 0, 0);
  position: sticky;
  z-index: 2;
  top: 0;

  @media (min-width: 572px) {
    /*display: none;*/
    background: none;
    border: none;

  }
}

.navbar-content.web {
  position: absolute;
  top: 20px;
  right: 20px;
}

.navbar-content.mobile {
  margin: 5px 20px;
  display: flex;
  width: 100%;
}

.navbar-right-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pointer {
  cursor: pointer;
}

.imageViewHide {
  transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
  opacity: 1;
}

.opacityVisible {
  animation: fadeIn 1s;
  animation-fill-mode: forwards;
}

.opacityHidden {
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mypdf-container-pages {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.report-datepicker{
  font-size: 28px;

  @media screen and (max-width: 720px) {
   
    font-size: 16px;
    height: 30px;
  }
}

.slack-card {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  height: 40px;
  color: white;
  max-width: 485px;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.slack-card.web {
  max-width: 485px;
}

.slack-card.mobile {
  max-width: calc(100vw - 15px);
}

.group-name {
  width: 50px;
  text-align: left;
}

.status-circle {
  width: 20px;
  height: 20px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  margin: 0 8px;
}

.machine-name {
  flex: 1;
  text-align: left;
  padding: 0 15px 0 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.right-group {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-right: 10px;
}

.envelope {
  filter: invert(1);
  width: 25px;
  height: 25px;
  margin: 0 3.5px;
}

.counter {
  display: flex;
  min-width: 32px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background-color: #a14ca7;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spacer {
  min-width: 32px;
}

.inspection-user-opener {
  background-color: rgb(166, 101, 168);
  border-radius: 4px;
  width: 48px;
  height: 48px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inspection-user-opener.selected {
  width: 85px;
}

.inspection-user-opener > .MuiAvatar-circular {
  width: 32px;
  height: 32px;
  font-size: 0.9rem;
}
